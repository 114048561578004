export const BOT_MODES = [
  {
    name: 'open',
    value: 'open',
  },
  {
    name: 'closed',
    value: 'closed',
  },
  {
    name: 'hybrid',
    value: 'hybrid',
  },
];

export const CHAT_CONSERVATION_PERIODS = [
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.1',
    value: 1,
  },
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.2',
    value: 2,
  },
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.3',
    value: 3,
  },
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.6',
    value: 6,
  },
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.12',
    value: 12,
  },
  {
    label: 'private.configurationPage.assistantPage.changeResponseLengthModal.chatConservationPeriods.24',
    value: 24,
  },
];

export const CHAT_SHARING_MODES = [
  {
    label: 'Condivisione Privata',
    value: 'private',
    description: 'La conversazione o porzione della stessa è visibile solo ad utenti di questo assistente',
  },
  {
    label: 'Condivisione Pubblica',
    value: 'public',
    description: 'La conversazione o porzione della stessa è visibile a chiunque abbia il link',
  },
];

export const REQUIRED_SYNC_VALIDATOR = 'required';

export const DEFAULT_BOT_PLACEHOLDER = 'assets/layout/images/placeholder-bot.png';

export const ALPHABET = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz23456789';

export const STARTER_MESSAGE_DEFAULT_VALUE = `common.assistantCreationDefaultMessage`;

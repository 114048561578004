interface iToken {
  access_token: string;
  expires_in: string;
  refresh_token: string;
  token_type: string;
  idToken?: string;
}

export class Token {
  accessToken: string = '';
  expiresIn: string = '';
  tokenType: string = '';
  refreshToken: string = '';
  idtoken?: string = '';

  constructor(token?: iToken) {
    this.accessToken = token?.access_token ?? '';
    this.expiresIn = token?.expires_in ?? '';
    this.tokenType = token?.token_type ?? '';
    this.refreshToken = token?.refresh_token ?? '';
    this.idtoken = token?.idToken ?? '';
  }
}

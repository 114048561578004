import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { clone, pull } from 'lodash';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import {
  IBotStoreState,
  ILastAssistantChat,
  clearBotAction,
  setBotAction,
  setBotFailureAction,
  setLastChatsAction,
  setWholeLastChatsAction,
} from 'src/app/core/store/bot';
import { selectBot, selectError, selectLastChats } from 'src/app/core/store/bot/bot.selectors';
import { CHAT_BOT_SELECTED_KEY } from '../shared/constants/local-storage-keys.consts';
import { LanguagesEnum } from '../shared/enums/app-languages.enum';
import { generateRandomString } from '../shared/functions/utilities.functions';
import { BotPreview, IBotPreview } from '../shared/models/bot-preview.model';
import { AssistantType, ChatBot, IChatBot, IHasStartedChat } from '../shared/models/chat-bot.model';
import { IMarketplaceItem } from '../shared/models/marketplace.model';
import { Settings, SettingsService } from './settings.service';
import { AiModelsService } from './ai-models.service';
import { AIModel } from '../shared/models/ai-models.model';
import { Duration } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class BotsService {
  bots$: BehaviorSubject<ChatBot[]> = new BehaviorSubject<ChatBot[]>([]);
  readonly currentBot$ = this.store.select(selectBot);
  readonly hasBotChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly _hasStartedChat$: Subject<IHasStartedChat> = new Subject();

  private currentBot?: ChatBot;
  private favouritesIds: string[] = [];

  readonly MAX_TIME_FOR_LAST_CHAT = Duration.fromObject({ hours: 2 });

  botModels: AIModel[] = [];

  constructor(
    private readonly apiService: ApiService,
    private readonly aiModelService: AiModelsService,
    private readonly settingsService: SettingsService,
    private readonly localStorageService: LocalstorageService,
    private readonly store: Store<{ botStore: IBotStoreState }>
  ) {
    this.botModels = this.aiModelService.aiModels;

    this.currentBot$.subscribe({
      next: (curr) => (this.currentBot = curr),
    });
  }

  getCurrentBot() {
    return this.currentBot;
  }

  getCurrentBotId() {
    return this.localStorageService.get(CHAT_BOT_SELECTED_KEY);
  }

  getChatBots() {
    return this.getBotsFromApi(false, false).pipe(switchMap(() => this.bots$.asObservable()));
  }

  getAssistants(archived: boolean) {
    return this.getBotsFromApi(true, archived).pipe(switchMap(() => this.bots$.asObservable()));
  }

  getMarketplaceAssistantById(marketplaceId: string) {
    return this.apiService.get<IMarketplaceItem>(`marketplace/${marketplaceId}`).pipe(
      map(
        (response) =>
          new ChatBot({
            active: true,
            apiKey: '',
            companyId: '',
            company: { id: '' },
            chatBotConfiguration: { id: '' },
            chatBotPreview: {},
            creatorId: response.creator.id,
            description: response.description,
            domain: '',
            failureMessage: '',
            id: response.id,
            imageUuid: response.imageUuid ?? '',
            language: response.storeCard?.language ?? LanguagesEnum.ENG_US,
            model: '',
            lastUseTimestamp: '',
            name: response.name,
            replyMode: 'open',
            responseLength: 'AUTO',
            starterMessage: '',
            status: response.status,
            type: 'MARKETPLACE',
            updatedAt: response.updatedAt,
            urlNavigation: response.feature.nativeInternetAccess,
            urlNavigationExtractText: response.feature.urlRetrieval,
            vendor: '',
            welcomeMessage: response.storeCard?.welcomeMessage ?? '',
          })
      )
    );
  }

  getAssistantById(botId: string) {
    return this.apiService.get<IChatBot>(`assistants/${botId}`).pipe(map((response) => new ChatBot(response)));
  }

  getChatBotById(botId: string) {
    // @todo - Workaround per aggiungere il tipo al bot
    return this.apiService.get<IChatBot>(`chat-bots/${botId}`).pipe(
      map(
        (response) =>
          new ChatBot({
            ...response,
            type: this.bots$.getValue().find((b) => b.id === response.id && b.name === response.name)?.type || 'ASSISTANT',
          })
      )
    );
  }

  getPreviewSettings(botId: string) {
    return this.apiService.get(`chat-bots/${botId}/preview`).pipe(
      map((response: any) => new BotPreview(response)),
      catchError((err: any) => {
        if (err.status === 404) {
          return of(new BotPreview());
        }
        throw err;
      })
    );
  }

  selectChatBot(botId: string) {
    this.getChatBotById(botId).subscribe({
      next: (bot) => {
        this.saveSelectedChatBot(bot);
        this.hasBotChanged$.next(true);
      },
    });
  }

  selectBot(botId: string, botType: AssistantType, isInConfiguration?: boolean) {
    this.getAssistant(botId, botType, isInConfiguration).subscribe({
      next: (bot) => {
        bot.type = botType;
        this.saveSelectedChatBot(bot);
        this.hasBotChanged$.next(true);
      },
      error: ({ error }) => this.store.dispatch(setBotFailureAction({ error })),
    });
  }

  private getAssistant(botId: string, botType: AssistantType, isInConfiguration?: boolean) {
    switch (botType) {
      case 'ASSISTANT':
        return isInConfiguration ? this.getChatBotById(botId) : this.getAssistantById(botId);
      case 'MARKETPLACE':
        return this.getMarketplaceAssistantById(botId);
      case 'GROUP':
        return of();
    }
  }

  createBot(body: any) {
    return this.apiService.post<IChatBot>('chat-bots', body).pipe(
      tap((response) =>
        this.store.dispatch(
          setBotAction({
            bot: new ChatBot({
              ...response,
              type: this.bots$.getValue().find((b) => b.id === response.id && b.name === response.name)?.type || 'ASSISTANT',
            }),
          })
        )
      ),
      map((response) => response)
    );
  }

  updateBot(idBot: string, body: any) {
    return this.apiService.patch<IChatBot>(`chat-bots/${idBot}`, body).pipe(
      map((response) => new ChatBot(response)),
      tap((assistant) => this.selectChatBot(assistant.id))
    );
  }

  deleteBot(botId: string) {
    return this.apiService.delete(`chat-bots/${botId}`).pipe(
      tap(() => this.store.dispatch(clearBotAction())),
      switchMap(() => this.getBotsFromApi(true, false))
    );
  }

  updateBotPreview$(botId: string, body: IBotPreview) {
    return this.apiService.patch(`chat-bots/${botId}/preview`, body);
  }

  getBotPreview(botId: string, password: string): Observable<ChatBot> {
    return this.apiService
      .get<IChatBot>(`preview/chat-bots/${botId}`, {
        'X-NSToken': password,
      })
      .pipe(
        map((response) => {
          const bot = new ChatBot(response);
          const botClone = clone(bot);
          botClone.selected = true;
          this.store.dispatch(setBotAction({ bot: botClone }));
          return bot;
        })
      );
  }

  generateSecureStringForDeletion(botName: string) {
    return `${botName.replace(/\s/g, '-')}-${generateRandomString(5)}`;
  }

  private getBotsFromApi(assistant: boolean, archived: boolean) {
    return combineLatest({
      bots: this.apiService.get<IChatBot[]>(`${assistant ? 'assistants' : 'chat-bots'}?archive=${archived}`),
      favourites: this.settingsService.getUserSetting(Settings.FAVOURITES),
    }).pipe(
      tap(({ bots, favourites }) => {
        const favIds = favourites['favourites'] as string[];
        this.favouritesIds = favIds;
        const chatBots = this.sortBotsByFavourites(
          bots.map((bot: IChatBot) => new ChatBot(bot)),
          favIds
        );
        this.bots$.next(chatBots);
        // if (chatBots.length) this.saveSelectedChatBot(this.currentBot?.id ?? chatBots[0].id);
      })
    );
  }

  private saveSelectedChatBot(bot: ChatBot) {
    this.store.dispatch(setBotAction({ bot }));
  }

  selectBotFromLocalStorage() {
    const bots = this.bots$.getValue();
    if (bots.length) {
      const id = this.getCurrentBotId() as string;
      if (id) {
        const foundBot = bots.find((bot) => bot.id === id);
        if (foundBot) {
          this.store.dispatch(setBotAction({ bot: foundBot }));
          this.localStorageService.remove(CHAT_BOT_SELECTED_KEY);
        }
      }
    }
  }

  pinChatbot(id: string, isPinned: boolean, archived: boolean) {
    const favIds = isPinned ? pull(this.favouritesIds, id) : [...this.favouritesIds, id];
    return this.settingsService
      .updateUserSetting(Settings.FAVOURITES, { favourites: favIds })
      .pipe(
        switchMap(() =>
          this.getBotsFromApi(true, archived).pipe(map(({ bots, favourites }) => ({ favourites, bots: bots.map((bot) => new ChatBot(bot)) })))
        )
      );
  }

  archiveChatbot(id: string, type: AssistantType, archive: boolean) {
    return this.apiService
      .post<any>(`${type === 'ASSISTANT' ? 'assistants' : 'marketplace'}/${id}/archive`, { archive })
      .pipe(switchMap(() => this.getBotsFromApi(true, !archive)));
  }

  private sortBotsByFavourites(bots: ChatBot[], ids: string[]) {
    return bots.sort((a, b) => {
      const aIncluded = ids.includes(a.id);
      const bIncluded = ids.includes(b.id);

      const aTimestamp = a.lastUseTimestamp ? a.lastUseTimestamp.toMillis() : null;
      const bTimestamp = b.lastUseTimestamp ? b.lastUseTimestamp.toMillis() : null;

      if (aIncluded) {
        if (a.setPinned) a.setPinned(true);
      }
      if (bIncluded) {
        if (b.setPinned) b.setPinned(true);
      }

      if (aIncluded && bIncluded) {
        if (aTimestamp !== null && bTimestamp !== null) {
          return bTimestamp - aTimestamp;
        } else if (aTimestamp !== null) {
          return -1;
        } else if (bTimestamp !== null) {
          return 1;
        } else {
          return 0;
        }
      } else if (aIncluded) {
        return -1;
      } else if (bIncluded) {
        return 1;
      } else {
        if (aTimestamp !== null && bTimestamp !== null) {
          return bTimestamp - aTimestamp;
        } else if (aTimestamp !== null) {
          return -1;
        } else if (bTimestamp !== null) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  getModelVendor(modelValue: string) {
    return this.botModels.find((model) => model.internalId === modelValue)?.vendor?.id;
  }

  getLastChats$() {
    return this.store.select(selectLastChats);
  }

  setLastChats$(lastChat: ILastAssistantChat) {
    this.store.dispatch(setLastChatsAction({ lastChat }));
  }

  setWholeLastChats$(lastChats: ILastAssistantChat[]) {
    this.store.dispatch(setWholeLastChatsAction({ lastChats }));
  }

  get hasStartedChat$() {
    return this._hasStartedChat$.asObservable();
  }

  set hasStartedChat$$(value: IHasStartedChat) {
    this._hasStartedChat$.next(value);
  }

  get botError$() {
    return this.store.select(selectError);
  }
}

export enum AiModelCapabilities {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
}

export enum AiModelStatus {
  PRODUCTION = 'PRODUCTION',
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  INACTIVE = 'INACTIVE',
  COMING = 'COMING',
}

export interface IAIModelVendor {
  id: string;
  description: string;
  internalId: string;
  logo: string;
  name: string;
}

export interface IAIModelTranslation {
  id: string;
  description: string;
  lang: string;
  aiModelId: string;
}

export interface IAIModel {
  id: string;
  internalId: string;
  name: string;
  description: string;
  cost: string;
  includedK: string;
  costExtraK: string;
  frontier: boolean;
  legacy: boolean;
  capabilities: AiModelCapabilities[];
  status: AiModelStatus;
  translations: IAIModelTranslation[];
  vendor: IAIModelVendor;
}

export class AIModel {
  id: string = '';
  internalId: string = '';
  name: string = '';
  description: string = '';
  cost: number = 0;
  includedK: number = 0;
  costExtraK: number = 0;
  frontier: boolean = false;
  legacy: boolean = false;
  capabilities: AiModelCapabilities[] = [];
  status: AiModelStatus = AiModelStatus.INACTIVE;
  translations: IAIModelTranslation[] = [];
  vendor?: IAIModelVendor;
  disabled: boolean = false;

  constructor(res?: IAIModel) {
    if (res) {
      this.id = res.id;
      this.internalId = res.internalId;
      this.name = res.name;
      this.description = res.description;
      this.cost = +res.cost;
      this.includedK = +res.includedK;
      this.costExtraK = +res.costExtraK;
      this.frontier = res.frontier;
      this.legacy = res.legacy;
      this.capabilities = res.capabilities;
      this.status = res.status;
      this.translations = res.translations;
      this.vendor = res.vendor;
      this.disabled = res.status === AiModelStatus.INACTIVE;
    }
  }
}

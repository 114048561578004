import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';

import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, tap } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { IInitialState } from 'src/app/core/store/language/language.reducer';
import { PrimeNGConfig } from 'primeng/api';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class MainLanguageService extends LanguageService {
  constructor(
    private config: PrimeNGConfig,
    private readonly _store: Store<{ language: IInitialState }>,
    private readonly _translate: TranslateService
  ) {
    super(_store, _translate);

    this.store
      .select('language')
      .pipe(
        tap(({ language }) => (this.defaultLanguage = language!)),
        switchMap(() => this.translate.get('primeng'))
      )
      .subscribe({
        next: (value) => this.config.setTranslation(value),
      });
  }

  set defaultLanguage(language: string) {
    this.translate.setDefaultLang(language);
    DateTime.local().setLocale(language);
  }

  get defaultLanguageGetter() {
    return this.defaultLanguage;
  }

  get defaultLanguage$() {
    return this.store.select('language').pipe(map((v) => v.language));
  }

  reloadLangs(lang: string) {
    return this.translate.reloadLang(lang);
  }

  instant(key: string, params?: Object) {
    return this.translate.instant(key, params);
  }

  get(key: string, params?: Object) {
    return this.translate.get(key, params);
  }

  stream(key: string, params?: Object) {
    return this.translate.stream(key, params);
  }
}

export enum LanguagesEnum {
  ITA = 'it-IT',
  IT = 'it',
  ENG = 'en-EN',
  ENG_US = 'en-US',
  EN = 'en',
  ESP = 'es-ES',
  ES = 'es',
  FRA = 'fr-FR',
  FR = 'fr',
  POL = 'pl-PL',
  PL = 'pl',
  ALB = 'sq-AL',
  AL = 'al',
  DEU = 'de-DE',
  DE = 'de',
  NED = 'nl-NL',
  NL = 'nl',
  TUR = 'tr-TR',
  TR = 'tr',
  KOR = 'ko-KR',
  KO = 'ko',
  CHN = 'zh-CN',
  ZH = 'zh',
  IND = 'hi-IN',
  HI = 'hi',
}

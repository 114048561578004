import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { OauthService } from 'src/app/core/services/auth/oauth.service';

import { BotsService } from 'src/app/main/services/bots.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly oAuthService: OauthService,
    private readonly botsService: BotsService,
    private router: Router
  ) {}

  readonly login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAction),
      exhaustMap((payload: any) =>
        this.oAuthService.login(payload.userLogin).pipe(
          map((token: any) => AuthActions.loginSuccessAction({ token })),
          catchError((error) => of(AuthActions.loginFailureAction({ error })))
        )
      )
    )
  );

  readonly loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessAction),
      map(() => AuthActions.getMeAction())
    )
  );

  // readonly loginGoogle$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loginGoogleAction),
  //     exhaustMap(() =>
  //       this.oAuthService.loginWithGoogle().pipe(
  //         map((token) =>
  //           AuthActions.loginGoogleSuccessAction({
  //             token,
  //           })
  //         ),
  //         // tap(() => this.router.navigate([''])),

  //         catchError((error) => of(AuthActions.loginGoogleFailureAction({ error })))
  //       )
  //     )
  //   )
  // );

  // readonly loginFacebook$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loginFacebookAction),
  //     exhaustMap(() =>
  //       this.oAuthService.loginWithFacebook().pipe(
  //         map((token) =>
  //           AuthActions.loginFacebookSuccessAction({
  //             token,
  //           })
  //         ),
  //         catchError((error) => of(AuthActions.loginFacebookFailureAction({ error })))
  //       )
  //     )
  //   )
  // );

  // readonly loginApple$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.loginAppleAction),
  //     exhaustMap(() =>
  //       this.oAuthService.loginWithApple().pipe(
  //         map(() => AuthActions.loginAppleSuccessAction({ token: '' })),
  //         catchError((error) => of(AuthActions.loginAppleFailureAction({ error })))
  //       )
  //     )
  //   )
  // );

  readonly register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerAction),
      exhaustMap((payload: any) =>
        this.oAuthService.register(payload.userLogin).pipe(
          map((token: any) => AuthActions.registerSuccessAction()),
          catchError((error) => of(AuthActions.registerFailureAction({ error })))
        )
      )
    )
  );

  readonly loginFacebookSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginFacebookSuccessAction),
      map(() => AuthActions.getMeAction())
    )
  );

  readonly loginGoogleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginGoogleSuccessAction),
      map(() => AuthActions.getMeAction())
    )
  );

  readonly logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutAction),
      // exhaustMap(() =>
      //   this.oAuthService.logout().pipe(
      //     map(() => AuthActions.logoutSuccessAction()),
      //     tap(() => this.router.navigate(['public'])),
      //     catchError((error) => of(AuthActions.logoutFailureAction({ error: error.toString() })))
      //   )
      // )
      map(() => AuthActions.logoutSuccessAction()),
      tap(() => this.router.navigate(['public']).then(() => this.botsService.bots$.next([])))
    )
  );

  readonly refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenAction),
      exhaustMap(({ token }) =>
        this.oAuthService.refreshToken(token).pipe(
          map((token) => AuthActions.refreshTokenSuccessAction({ token })),
          catchError((error) => of(AuthActions.refreshTokenFailureAction({ error })))
        )
      )
    )
  );

  readonly getMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getMeAction),
      switchMap(() =>
        this.oAuthService.profile().pipe(
          map((user: any) => AuthActions.getMeSuccessAction({ user })),
          // tap(() => this.router.navigate(['private'])),
          catchError((error) => of(AuthActions.getMeFailureAction({ error })))
        )
      )
    )
  );

  readonly updateMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateMeAction),
      switchMap(() =>
        this.oAuthService.profile().pipe(
          map((user: any) => AuthActions.getMeSuccessAction({ user })),
          catchError((error) => of(AuthActions.getMeFailureAction({ error })))
        )
      )
    )
  );

  readonly forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPasswordAction),
      switchMap(({ username }) =>
        this.oAuthService.forgotPassword(username).pipe(
          map(() => AuthActions.forgotPasswordActionSuccess()),
          catchError((error) => of(AuthActions.forgotPasswordActionFailure({ error })))
        )
      )
    )
  );

  readonly changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePasswordAction),
      switchMap((payload) =>
        this.oAuthService.changePassword({ newPassword: payload.newPassword, oldPassword: payload.oldPassword }).pipe(
          map(() => AuthActions.changePasswordActionSuccess()),
          catchError((error) => of(AuthActions.changePasswordActionFailure({ error })))
        )
      )
    )
  );
}

import { groupBy } from 'lodash';
import { ALPHABET } from '../constants/bot.consts';
import { AIModel } from '../models/ai-models.model';

export const generateFixedNumbers = (min: number, max: number) => {
  const array = [];
  for (let i = 0; i < max; i++) {
    array[i] = min;
    min++;
  }
  return array;
};

export const generateRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const dataURItoBlob = (dataURI: string) => {
  var byteString = atob(dataURI.split(',')[1]);

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const generateRandomString = (length: number) => {
  let result = '';
  let counter = 0;
  while (counter < length) {
    result += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    counter += 1;
  }
  return result;
};

export const sortAlphabetically = (a: any, b: any, key: string) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const average = (ctx: any) => {
  const values = ctx.chart.data.datasets[0].data;
  return values.reduce((a: number, b: number) => a + b, 0) / values.length;
};

export const convertToCSV = (data: string): string => {
  const rows = data.split('\n').filter((row) => row.trim() !== '');

  const processedRows = rows
    .map((row) => {
      return row
        .split(',')
        .map((field) => (field.trim() === '' ? '-' : field))
        .join(',');
    })
    .join('\n');

  const csvContent = processedRows;
  return csvContent;
};

export const downloadCSV = (csvContent: string, filename: string): void => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

export const getGroupedBotModels = (models: AIModel[]) => {
  const groups = groupBy(models, 'vendor.name');
  const g = Object.entries(groups).map(([key, value]) => ({ label: key, items: value }));
  return g;
};

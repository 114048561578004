import { createAction, props } from '@ngrx/store';

import { BOT_ACTIONS } from './bot.enum';
import { ChatBot } from 'src/app/main/shared/models/chat-bot.model';
import { ILastAssistantChat, ILastChatBot } from './bot.reducer';

export const setBotAction = createAction(BOT_ACTIONS.setBot, props<{ bot: ChatBot }>());
export const setBotSuccessAction = createAction(BOT_ACTIONS.setBotSuccess, props<{ bot: ChatBot }>());
export const setBotFailureAction = createAction(BOT_ACTIONS.setBotFailure, props<{ error: any }>());

export const setLastChatsAction = createAction(BOT_ACTIONS.setLastChat, props<{ lastChat: ILastAssistantChat }>());
export const setLastChatsSuccessAction = createAction(BOT_ACTIONS.setLastChatSuccess, props<{ lastChats: ILastAssistantChat[] }>());
export const setLastChatsFailureAction = createAction(BOT_ACTIONS.setLastChatFailure, props<{ error: any }>());

export const removeLastChatAction = createAction(BOT_ACTIONS.removeLastChat, props<{ bot: ILastChatBot }>());
export const removeLastChatSuccessAction = createAction(BOT_ACTIONS.removeLastChatSuccess, props<{ lastChats: ILastAssistantChat[] }>());
export const removeLastChatFailureAction = createAction(BOT_ACTIONS.removeLastChatFailure, props<{ error: any }>());

export const setWholeLastChatsAction = createAction(BOT_ACTIONS.setWholeLastChat, props<{ lastChats: ILastAssistantChat[] }>());

export const clearBotAction = createAction(BOT_ACTIONS.clearBot);

export interface IBotPreview {
  enabled?: boolean;
  showKb?: boolean;
  previewForm?: string; //Form custom per la Preview
  previewMessage?: string;
  showEngine?: boolean;
  showSeed?: boolean;
  password?: string; //min lenght 5
}
export class BotPreview {
  enabled: boolean;
  showKb: boolean;
  previewForm: any[]; //Form custom per la Preview
  previewMessage: string;
  showEngine: boolean;
  showSeed: boolean;
  password: string; //min lenght 5

  constructor(config?: IBotPreview) {
    this.enabled = config?.enabled ?? true;
    this.showKb = config?.showKb ?? false;
    try {
      if (config?.previewForm === '{}') this.previewForm = [];
      else this.previewForm = JSON.parse(config?.previewForm ?? '[]');
    } catch (e) {
      this.previewForm = [];
    }
    this.previewMessage = config?.previewMessage || 'Preview Message for Testers';
    this.showEngine = config?.showEngine ?? true;
    this.showSeed = config?.showSeed ?? true;
    this.password = config?.password || Math.random().toString(36).slice(-8);
  }
}

import { createSelector } from '@ngrx/store';

import { AuthState } from './auth.reducer';

export const selectAuthState = (state: { auth: AuthState }) => state.auth;

export const selectUser = createSelector(
  selectAuthState,
  (state) => state.user
);

export const selectToken = createSelector(
  selectAuthState,
  (state) => state.token
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectAuthenticate = createSelector(
  selectToken,
  selectUser,
  (token, user) => !!token && !!user
);

export const loginSucces = createSelector(selectAuthState, (result) => result);

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
  export class CacheService {
    private cache = new Map<string, { value: any; expiration: number }>();

    /**
     * Sets a value in the cache with an optional expiration time and persistence flag.
     * @param key The key to store the value under.
     * @param value The value to be stored.
     * @param expirationMs The expiration time in milliseconds (default: 1 hour).
     * @param persist Whether to persist the value in localStorage (default: false).
     */
    set(key: string, value: any, expirationMs: number = 3600000, persist: boolean = false) {
        const expiration = Date.now() + expirationMs;
        this.cache.set(key, { value, expiration });
        if (persist) {
            localStorage.setItem(key, JSON.stringify({ value, expiration }));
        }
    }

    /**
     * Retrieves a value from the cache.
     * @param key The key of the value to retrieve.
     * @returns The cached value if it exists and hasn't expired, otherwise null.
     */
    get(key: string) {
        const cached = this.cache.get(key);
        if (cached && Date.now() < cached.expiration) {
            return cached.value;
        }
        
        const persisted = this.getPersisted(key);
        if (persisted && Date.now() < persisted.expiration) {
            this.cache.set(key, persisted);
            return persisted.value;
        }

        this.cache.delete(key);
        localStorage.removeItem(key);
        return null;
    }

    /**
     * Retrieves a persisted value from localStorage.
     * @param key The key of the persisted value to retrieve.
     * @returns The parsed value from localStorage if it exists, otherwise null.
     * @private
     */
    private getPersisted(key: string) {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }
  }

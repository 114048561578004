export enum AUTH {
  login = '[Login Page] Login',
  loginSucces = '[Login Process] Login Success',
  loginFailure = '[Login Process] Login Failure',
  register = '[register Page] register',
  registerSucces = '[register Process] register Success',
  registerFailure = '[register Process] register Failure',
  loginGoogle = '[LoginGoogle Page] LoginGoogle',
  loginGoogleSucces = '[LoginGoogle Process] LoginGoogle Success',
  loginGoogleFailure = '[LoginGoogle Process] LoginGoogle Failure',
  loginFacebook = '[LoginFacebook Page] LoginFacebook',
  loginFacebookSucces = '[LoginFacebook Process] LoginFacebook Success',
  loginFacebookFailure = '[LoginFacebook Process] LoginFacebook Failure',
  loginApple = '[LoginApple Page] LoginApple',
  loginAppleSuccess = '[LoginApple Page] LoginApple Success',
  loginAppleFailure = '[LoginApple Page] LoginApple Failure',
  logout = '[Login Page] Logout',
  logoutSucces = '[Logout Process] Logout Success',
  logoutFailure = '[Logout Process] Logout Failure',
  refreshToken = '[Refresh Token Process] Refresh Token',
  refreshTokenSuccess = '[Refresh Token Process] Refresh Token Success',
  refreshTokenFailure = '[Refresh Token Process] Refresh Token Failure',
  me = '[User] Get me',
  meSuccess = '[User] Get me success',
  meFailure = '[User] Get me failure',
  updateMe = '[User] update me',

  forgotPassword = '[AUTH ] Forgot Password',
  forgotPasswordSuccess = '[AUTH ] Forgot Password Success',
  forgotPasswordFailure = '[AUTH ] Forgot Password Failure',

  changePassword = '[AUTH ] Change Password',
  changePasswordSuccess = '[AUTH ] Change Password Success',
  changePasswordFailure = '[AUTH ] Change Password Failure',
}

export enum BOT {
  getBot = '[Get Bot] bot',
  getBotSuccess = '[Get Bot] bot',
  getBotFailure = '[Get Bot] bot',
  selectBot = '[Select Bot] bot',
  selectBotSuccess = '[Select Bot] bot',
  selectBotFailure = '[Select Bot] bot',
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Store } from '@ngrx/store';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InvitationsService } from 'src/app/main/services/invitations.service';
import { Token } from '../../models/token.model';
import { UserLogin } from '../../models/userCore.model';
import { AuthState, loginFailureAction, loginSuccessAction } from '../../store/auth';
import { ApiService } from '../api.service';
import { ILoginSSODTO } from 'src/app/main/shared/models/sso.model';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  constructor(
    private readonly apiService: ApiService,
    private readonly afAuth: AngularFireAuth,
    private readonly store: Store<{ auth: AuthState }>,
    private readonly invitationService: InvitationsService
  ) {}

  login(body: UserLogin) {
    const bodyFake = {
      username: body.email,
      password: body.password,
      client_id: 1,
      grant_type: 'password',
    };
    return this.apiService.post('auth/login', bodyFake).pipe(map((token: any) => new Token(token)));
  }

  currentUser() {
    return from(this.afAuth.currentUser);
  }

  loginSso(body: Partial<ILoginSSODTO>, type: 'google' | 'facebook' | 'apple') {
    const headers = {
      provider: type,
    };
    return this.apiService.post(`oauth`, body, headers).pipe(
      catchError(({ error }) => throwError(() => this.store.dispatch(loginFailureAction({ error })))),
      map((token: any) => new Token(token)),
      map((token) => this.store.dispatch(loginSuccessAction({ token })))
    );
  }

  logout() {
    return this.apiService.delete('auth/logout');
  }

  registerFromInvitated(secret: string, id: string, body: any) {
    return this.invitationService.registerFromInvitated$(secret, id, body);
  }

  register(body: any) {
    return this.apiService.post('auth/register', body);
  }

  profile() {
    return this.apiService.get('auth/profile');
  }

  refreshToken(token: string) {
    return this.apiService
      .post(`auth/login`, {
        grant_type: 'refresh_token',
        refresh_token: token,
      })
      .pipe(map((token: any) => new Token(token)));
  }

  forgotPassword(username: string) {
    return this.apiService.post('auth/forgot-password', { username });
  }

  changePassword(body: { oldPassword: string; newPassword: string }) {
    return this.apiService.patch('auth/change-password', body);
  }
}

import { DateTime } from 'luxon';
import { DEFAULT_BOT_PLACEHOLDER } from '../constants/bot.consts';
import { ResponseLength } from '../types/response-length.type';
import { BotPreview, IBotPreview } from './bot-preview.model';

export interface IHasStartedChat {
  conversationId: string;
  assistant: ChatBot;
}

export interface ICreateAssistantGroupDTO {
  name: string;
  moderatorIds: string;
  chatBotIds: string[];
}

interface IAssistantUses {
  id: string;
  archived: boolean;
  lastUseTimestamp: string | null;
  chatBotId: string;
  marketplaceId: string | null;
  userId: string;
}

class AssistantUses {
  id: string = '';
  archived: boolean = false;
  lastUseTimestamp: DateTime | null = null;
  chatBotId: string = '';
  marketplaceId: string | null = null;
  userId: string = '';

  constructor(res?: IAssistantUses) {
    if (res) {
      this.id = res.id;
      this.archived = res.archived;
      if (res.lastUseTimestamp) this.lastUseTimestamp = DateTime.fromISO(res.lastUseTimestamp);
      this.chatBotId = res.chatBotId;
      this.marketplaceId = res.marketplaceId;
      this.userId = res.userId;
    }
  }
}

export interface IChatBot {
  id: string;
  active: boolean;
  name: string;
  description: string;
  // assistantUses: IAssistantUses[];
  lastUseTimestamp: string | null;
  updatedAt: string;
  apiKey: string;
  domain: string;
  imageUuid: string | null;
  failureMessage: string;
  starterMessage: string;
  welcomeMessage: string;
  type: 'ASSISTANT' | 'MARKETPLACE' | 'GROUP';
  model: string;
  vendor: string;
  language: string;
  status: string;
  companyId: string;
  creatorId: string;
  responseLength: ResponseLength;
  replyMode: 'open' | 'closed' | 'hybrid';
  company: IChatBotConfiguration;
  chatBotConfiguration: IChatBotConfiguration;
  chatBotPreview: IBotPreview | undefined;
  urlNavigation: boolean;
  urlNavigationExtractText: boolean;
}

export interface IChatBotConfiguration {
  id: string;
  onboardingForm?: string;
  description?: string;
}

export type AssistantType = 'ASSISTANT' | 'MARKETPLACE' | 'GROUP';

export class ChatBot {
  id: string = '';
  active: boolean = false;
  name: string = '';
  updatedAt: DateTime = DateTime.now();
  lastUseTimestamp: DateTime | null = null;
  description: string = '';
  apiKey: string = '';
  domain: string = '';
  language: string = '';
  type?: AssistantType;
  imageUuid: string | null = null;
  status: string = '';
  companyId: string = '';
  creatorId: string = '';
  selected: boolean = false;
  failureMessage: string = '';
  starterMessage: string = '';
  welcomeMessage: string = '';
  model: string = '';
  vendor: string = '';
  responseLength: ResponseLength = 'SHORT';
  replyMode: 'open' | 'closed' | 'hybrid' = 'open';
  company: IChatBotConfiguration = { id: '' };
  chatBotConfiguration: {
    onBoardingForm?: any[];
    // onBoardingConfigFormGroup: FormGroup<{}>;
  } = {};
  chatBotPreview?: BotPreview;
  urlNavigation: boolean = false;
  urlNavigationExtractText: boolean = false;
  pinned: boolean = false;

  constructor(data?: IChatBot) {
    if (data) {
      this.id = data.id;
      this.active = data.active;
      this.name = data.name;
      this.description = data.description;
      this.updatedAt = DateTime.fromISO(data.updatedAt);
      // this.assistantUses = this.mapAssistantUses(data.assistantUses);
      if (data.lastUseTimestamp) this.lastUseTimestamp = DateTime.fromISO(data.lastUseTimestamp);
      this.apiKey = data.apiKey;
      this.domain = data.domain;
      this.language = data.language;
      this.type = data.type;
      this.imageUuid = data.imageUuid;
      this.status = data.status;
      this.companyId = data.companyId;
      this.creatorId = data.creatorId;
      this.failureMessage = data.failureMessage;
      this.starterMessage = data.starterMessage;
      this.welcomeMessage = data.welcomeMessage;
      this.model = data.model;
      this.vendor = data.vendor;
      this.responseLength = data.responseLength;
      this.replyMode = data.replyMode;
      this.company = data.company;
      this.chatBotConfiguration = {
        onBoardingForm: JSON.parse(data.chatBotConfiguration?.onboardingForm ?? '[]'),
        // onBoardingConfigFormGroup: checkIfOnboardingFormIsValid(data.chatBotConfiguration?.onboardingForm ?? '[]', new ValidatorsService()),
      };
      if (data.chatBotPreview) this.chatBotPreview = new BotPreview(data.chatBotPreview);
      this.urlNavigation = data.urlNavigation;
      this.urlNavigationExtractText = data.urlNavigationExtractText;
    }
  }

  setPinned(pinned: boolean) {
    this.pinned = pinned;
  }
}

export interface ICreateBotDTO {
  enabled: boolean;
  starterMessage: string;
  failureMessage: string;
  welcomeMessage: string;
  replyMode: string;
  description: string;
  language: string;
  vendor: string;
  //enabled: this.formGeneral.get('enabled').value,
  image: string;
  model: string;
  botName: string;
  name: string;
  status: string;
  companyId: string;
  domain: string;
  responseLength: string;
  urlNavigation: boolean;
  urlNavigationExtractText: boolean;
}

export interface IBotsMenuItem {
  name?: string;
  code?: string;
  icon?: string;
  role?: string;
  image?: string;
  model?: string;
  type?: AssistantType;
}

export interface IOmnibox {
  botId: string;
  startingMessage: string;
  files?: File;
}

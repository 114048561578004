import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ISetting, Setting } from '../shared/models/settings.model';
import { map } from 'rxjs';

export enum Settings {
  FAVOURITES = 'U_FAVOURITE_CONVERSATION',
  KB_UI_OPTIONS = 'ASSISTANT_KB_UI_OPTIONS',
  ASSISTANT_SHOW_KB_MATCH = 'ASSISTANT_SHOW_KB_MATCH',
  ASSISTANT_SHOW_KB_RESOURCES = 'ASSISTANT_SHOW_KB_RESOURCES',
  ASSISTANT_KB_RESOURCES_DOWNLOAD = 'ASSISTANT_KB_RESOURCES_DOWNLOAD',
  ASSISTANT_MARKETING_TOOLS = 'ASSISTANT_MARKETING_TOOLS',
  A_LEAD_GENERATION_FORM = 'A_LEAD_GENERATION_FORM',
  A_NEW_LEADS_MAIL = 'A_NEW_LEADS_MAIL',
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly apiService: ApiService) {}

  getAvailableSettings() {
    return this.apiService.get<ISetting[]>('settings/available').pipe(map((array) => array.map((s) => new Setting(s))));
  }

  getAvailableSettingById(id: string) {
    return this.apiService.get<ISetting>(`settings/available/${id}`).pipe(map((s) => new Setting(s)));
  }

  createSetting(body: ISetting) {
    return this.apiService.post<any>('settings/available', body);
  }

  updateAvailableSetting(id: string, body: any) {
    return this.apiService.patch<any>(`settings/available/${id}`, body);
  }

  getUserSetting(id: string) {
    return this.apiService.get<{ [key: string]: any }>(`settings/user?availableSettingId=${id}`);
  }

  updateUserSetting(id: string, value: { [key: string]: any }) {
    return this.apiService.put<any>(`settings/user`, [
      {
        availableSettingId: id,
        value,
      },
    ]);
  }

  getGroupSetting<T extends { [key in Settings]?: { enable: boolean } }>(id: string, assistantId?: string) {
    return this.apiService.get<T>(`settings/company?settingGroupId=${id}${assistantId ? `&assistantId=${assistantId}` : ''}`);
  }

  updateCompanySetting(id: string, body: any) {
    return this.apiService.put<any>(`settings/company`, [
      {
        availableSettingId: id,
        value: body,
      },
    ]);
  }

  updateMultipleCompanySettingsForAssistant(assistantId: string, settingId: string, body: any[]) {
    return this.apiService.put<any>(`settings/company`, body);
  }

  updateCompanySettingForAssistant(assistantId: string, settingId: string, body: any) {
    return this.apiService.put<any>(`settings/company`, [
      {
        availableSettingId: settingId,
        value: body,
        assistantId,
      },
    ]);
  }
}

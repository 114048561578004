interface IDataType {
  settingName: string;
  active: string;
}

interface IDefaultValue {
  settingName: string;
  active: any;
}

export interface ISetting {
  availableSettingId: string;
  dataType: IDataType | string;
  defaultValue: IDefaultValue | string;
  description: string;
  scope: string;
  settingGroupId: string;
  status: string;
  version: number;
}

export class Setting {
  availableSettingId: string = '';
  dataType?: IDataType;
  defaultValue?: IDefaultValue;
  description: string = '';
  scope: string = '';
  settingGroupId: string = '';
  status: string = '';
  version: number = 0;

  constructor(res?: ISetting) {
    if (res) {
      this.availableSettingId = res.availableSettingId;
      this.dataType = typeof res.dataType === 'string' ? JSON.parse(res.dataType) : res.dataType;
      this.defaultValue = typeof res.defaultValue === 'string' ? JSON.parse(res.defaultValue) : res.defaultValue;
      this.description = res.description;
      this.scope = res.scope;
      this.settingGroupId = res.settingGroupId;
      this.status = res.status;
      this.version = res.version;
    }
  }
}

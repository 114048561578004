import { createAction, props } from '@ngrx/store';
import { AUTH } from 'src/app/core/enums/auth.enum';
import { Token } from 'src/app/core/models/token.model';
import { IUserRegister, UserLogin } from 'src/app/core/models/userCore.model';
import { User } from 'src/app/main/shared/models/user.model';

export const loginAction = createAction(AUTH.login, props<{ userLogin: UserLogin }>());

export const loginSuccessAction = createAction(AUTH.loginSucces, props<{ token: Token }>());

export const loginFailureAction = createAction(AUTH.loginFailure, props<{ error: any }>());

export const registerAction = createAction(AUTH.register, props<{ userLogin: IUserRegister }>());

export const registerSuccessAction = createAction(AUTH.registerSucces);

export const registerFailureAction = createAction(AUTH.registerFailure, props<{ error: any }>());

export const loginGoogleAction = createAction(AUTH.loginGoogle);

export const loginGoogleSuccessAction = createAction(AUTH.loginGoogleSucces, props<{ token: any }>());

export const loginGoogleFailureAction = createAction(AUTH.loginGoogleFailure, props<{ error: any }>());

export const loginFacebookAction = createAction(AUTH.loginFacebook);

export const loginFacebookSuccessAction = createAction(AUTH.loginFacebookSucces, props<{ token: any }>());

export const loginFacebookFailureAction = createAction(AUTH.loginFacebookFailure, props<{ error: any }>());

export const loginAppleAction = createAction(AUTH.loginApple);
export const loginAppleSuccessAction = createAction(AUTH.loginAppleSuccess, props<{ token: any }>());
export const loginAppleFailureAction = createAction(AUTH.loginAppleFailure, props<{ error: any }>());

export const logoutAction = createAction(AUTH.logout);

export const logoutSuccessAction = createAction(AUTH.logoutSucces);

export const logoutFailureAction = createAction(AUTH.logoutFailure, props<{ error: any }>());

export const refreshTokenAction = createAction(AUTH.refreshToken, props<{ token: string }>());

export const refreshTokenSuccessAction = createAction(AUTH.refreshTokenSuccess, props<{ token: Token }>());

export const refreshTokenFailureAction = createAction(AUTH.refreshTokenFailure, props<{ error: any }>());

export const getMeAction = createAction(AUTH.me);
export const updateMeAction = createAction(AUTH.updateMe);

export const getMeSuccessAction = createAction(AUTH.meSuccess, props<{ user: User }>());

export const getMeFailureAction = createAction(AUTH.meFailure, props<{ error: any }>());

export const forgotPasswordAction = createAction(AUTH.forgotPassword, props<{ username: string }>());

export const forgotPasswordActionSuccess = createAction(AUTH.forgotPasswordSuccess);

export const forgotPasswordActionFailure = createAction(AUTH.forgotPasswordFailure, props<{ error: any }>());

export const changePasswordAction = createAction(AUTH.changePassword, props<{ oldPassword: string; newPassword: string }>());

export const changePasswordActionSuccess = createAction(AUTH.changePasswordSuccess);

export const changePasswordActionFailure = createAction(AUTH.changePasswordFailure, props<{ error: any }>());

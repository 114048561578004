import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  BASE_URL = environment.BASE_URL;
  constructor(private readonly http: HttpClient, private readonly route: ActivatedRoute) {
    this.route.queryParams.subscribe({
      next: (p) => {
        if (p['local'] && p['local'] === 'true') {
          this.BASE_URL = 'http://localhost:8080';
        }
      },
    });
  }
  get<T>(path: string, headers?: any) {
    return this.http.get<T>(`${this.BASE_URL}/${path}`, { headers });
  }

  post<T>(path: string, body: any, headers?: any) {
    const headersToClone = new HttpHeaders(headers);
    const requestOptions = { headers: headersToClone };
    return this.http.post<T>(`${this.BASE_URL}/${path}`, body, requestOptions);
  }

  put<T>(path: string, body: any, headers?: any) {
    return this.http.put<T>(`${this.BASE_URL}/${path}`, body, { headers });
  }

  patch<T>(path: string, body: any, headers?: any) {
    return this.http.patch<T>(`${this.BASE_URL}/${path}`, body, { headers });
  }

  delete<T>(path: string, headers?: any, body?: any) {
    return this.http.delete<T>(`${this.BASE_URL}/${path}`, { headers, body });
  }

  postWithProgress<T>(path: string, body: any, headers?: any) {
    const req = new HttpRequest('POST', `${this.BASE_URL}/${path}`, body, {
      reportProgress: true,
      ...headers,
    });
    return this.http.request<T>(req);
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import { AssistantType, ChatBot } from 'src/app/main/shared/models/chat-bot.model';

import * as BotActions from './bot.actions';

export interface ILastChatBot {
  id: string;
  name: string;
  type: AssistantType;
}

export interface ILastAssistantChat {
  bot: ILastChatBot;
  lastMessageSent: string;
  conversationId: string;
}

export interface IBotStoreState {
  bot?: ChatBot;
  lastChats: ILastAssistantChat[];
  error?: any;
}

export const storeState: IBotStoreState = {
  bot: undefined,
  lastChats: [],
  error: undefined,
};

export const storeReducer = createReducer(
  storeState,
  on(BotActions.setBotSuccessAction, (state, { bot }) => ({
    ...state,
    bot,
    error: undefined,
  })),
  on(BotActions.setBotFailureAction, (state, { error }) => ({
    ...state,
    bot: undefined,
    error,
  })),
  on(BotActions.setLastChatsSuccessAction, (state, { lastChats }) => ({
    ...state,
    lastChats,
    error: undefined,
  })),
  on(BotActions.setLastChatsFailureAction, (state, { error }) => ({
    ...state,
    lastChats: [],
    error,
  })),
  on(BotActions.removeLastChatSuccessAction, (state, { lastChats }) => ({
    ...state,
    lastChats,
    error: undefined,
  })),
  on(BotActions.clearBotAction, (state) => ({
    ...state,
    bot: undefined,
    lastChats: [],
    error: undefined,
  }))
);

export function botStateReducerFactory(state: IBotStoreState | undefined, action: Action) {
  return storeReducer(state, action);
}

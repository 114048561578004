import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, of, withLatestFrom } from 'rxjs';
import * as AuthActions from '../auth/auth.actions';

import { Store } from '@ngrx/store';
import * as BotActions from './bot.actions';
import { IBotStoreState, ILastAssistantChat, ILastChatBot } from './bot.reducer';
import { selectLastChats } from './bot.selectors';
import { remove } from 'lodash';

@Injectable()
export class BotEffects {
  private readonly lastChats$ = this.store.select(selectLastChats);
  lastChats: ILastAssistantChat[] = [];

  constructor(private readonly actions$: Actions, private readonly store: Store<{ botStore: IBotStoreState }>) {
    this.lastChats$.subscribe({
      next: (lastChats) => {
        this.lastChats = lastChats;
      },
    });
  }

  readonly clearCurrentBot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutAction),
      map(() => BotActions.clearBotAction())
    )
  );

  readonly setBot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BotActions.setBotAction),
      map(({ bot }) => BotActions.setBotSuccessAction({ bot })),
      catchError((e) => of(BotActions.setBotFailureAction({ error: e })))
    )
  );

  readonly setLastChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BotActions.setLastChatsAction),
      map(({ lastChat }) => {
        const tempArray = [...this.lastChats, lastChat];

        remove(tempArray, (item) => findItem(item.bot, lastChat));
        tempArray.push(lastChat);

        return BotActions.setLastChatsSuccessAction({ lastChats: tempArray });
      })
    )
  );

  readonly setWholeLastChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BotActions.setWholeLastChatsAction),
      map(({ lastChats }) => BotActions.setLastChatsSuccessAction({ lastChats })),
      catchError((error) => of(BotActions.setLastChatsFailureAction({ error })))
    )
  );

  readonly removeLastChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BotActions.removeLastChatAction),
      map((propsBot) => {
        const tempArray = this.lastChats.filter((lastChat) => findItem(propsBot.bot, lastChat));

        return BotActions.removeLastChatSuccessAction({ lastChats: tempArray });
      }),
      catchError((error) => of(BotActions.removeLastChatFailureAction({ error })))
    )
  );
}

export const findItem = (item: ILastChatBot, lastChat: ILastAssistantChat) =>
  item.id === lastChat.bot.id && item.name === lastChat.bot.name && lastChat.bot.type === item.type;

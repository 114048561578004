import { Action, createReducer, on } from '@ngrx/store';
import { Token } from 'src/app/core/models/token.model';

import * as AuthActions from './auth.actions';
import { User } from 'src/app/main/shared/models/user.model';

export interface ErrorState {
  actionType: string;
  message: string;
  statusCode: number;
  statusText: string;
}

export interface AuthState {
  user?: User;
  token?: Token;
  error?: ErrorState;
}

export const initialState: AuthState = {
  user: undefined,
  token: undefined,
  error: undefined,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.loginAction, (state) => ({ ...state })),
  on(AuthActions.loginSuccessAction, (state, payload) => ({
    ...state,
    token: payload.token,
    error: undefined,
  })),
  on(AuthActions.loginFailureAction, (state, payload) => ({
    ...state,
    token: undefined,
    error: {
      actionType: AuthActions.loginFailureAction.type,
      message: payload.error?.message,
      statusCode: payload.error.status,
      statusText: payload.error.statusText || payload.error?.message,
    },
  })),
  on(AuthActions.loginFacebookAction, (state) => ({ ...state })),
  on(AuthActions.loginFacebookSuccessAction, (state, payload) => ({
    ...state,
    token: payload.token,
    error: undefined,
  })),
  on(AuthActions.loginGoogleAction, (state) => ({ ...state })),
  on(AuthActions.loginGoogleSuccessAction, (state, payload) => ({
    ...state,
    token: payload.token,
    error: undefined,
  })),
  on(AuthActions.registerAction, (state) => ({ ...state })),
  on(AuthActions.getMeAction, (state) => ({ ...state })),
  on(AuthActions.updateMeAction, (state) => ({ ...state })),
  on(AuthActions.getMeSuccessAction, (state, payload) => ({
    ...state,
    user: payload.user,
    error: undefined,
  })),
  on(AuthActions.getMeFailureAction, (state, payload) => ({
    ...state,
    error: {
      actionType: AuthActions.getMeAction.type,
      message: payload.error,
      statusCode: payload.error,
      statusText: payload.error,
    },
  })),
  on(AuthActions.logoutSuccessAction, (state) => ({
    ...state,
    user: undefined,
    token: undefined,
  })),
  on(AuthActions.logoutFailureAction, (state) => ({
    ...state,
    user: undefined,
    token: undefined,
  })),
  on(AuthActions.refreshTokenSuccessAction, (state, { token }) => ({
    ...state,
    token,
    error: undefined,
  })),
  on(AuthActions.refreshTokenFailureAction, (state, { error }) => ({
    ...state,
    token: undefined,
    error: {
      actionType: AuthActions.getMeAction.type,
      message: error.message,
      statusCode: error,
      statusText: error,
    },
  }))
);

export function authReducerFactory(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

export enum BOT_ACTIONS {
  setBot = '[Set Bot] Set',
  setBotSuccess = '[Set Bot] Set Success',
  setBotFailure = '[Set Bot] Set Failure',
  setLastChat = '[Set Last Chat Array] Set',
  setLastChatSuccess = '[Set Last Chat Array] Set Success',
  setLastChatFailure = '[Set Last Chat Array] Set Failure',
  setWholeLastChat = '[Set Whole Last Chat Array] Set',
  removeLastChat = '[Remove Last Chat] Remove',
  removeLastChatSuccess = '[Remove Last Chat] Remove Success',
  removeLastChatFailure = '[Remove Last Chat] Remove Failure',
  clearBot = '[Clear Bot] Clear',
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { setLanguageAction } from '../store/language/language.actions';
import { IInitialState } from '../store/language/language.reducer';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(protected readonly store: Store<{ language: IInitialState }>, protected readonly translate: TranslateService) {
    this.store.select('language').subscribe();

    new Intl.Locale('sq-AL');
  }

  useLanguage(language: string) {
    this.store.dispatch(setLanguageAction({ language }));
  }

  addLanguages(languages: string[]) {
    this.translate.addLangs(languages);
  }
}

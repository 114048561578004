import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ICreateInvitationDTO } from '../shared/models/invitation.model';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  constructor(private readonly apiService: ApiService) {}

  getInvitationById$(id: string, secret: string) {
    return this.apiService.get(`user-invitations/${id}?secret=${secret}`);
  }

  createInvitation$(body: ICreateInvitationDTO) {
    return this.apiService.post(`manage/user-invitations`, body);
  }

  registerFromInvitated$(secret: string, id: string, body: any) {
    return this.apiService.post(`auth/register?secret=${secret}&invitationId=${id}`, body);
  }
}
